import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDetails } from "../utils/fetchRequests";
import { DiscussionSlicType } from "../types/CourseTypes";

const initialState : DiscussionSlicType = {
  discussion: {
    loading: true,
    data: [],
  },
  error: "",
};

export const fetchDiscussion = createAsyncThunk(
  "getDiscussion",
  async () => {
    try {
      const response = await fetchDetails(
        `discussions/get-course-list-comment`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const discussionSlice = createSlice({
  name: "discussion",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDiscussion.pending, (state) => {
        state.discussion.loading = true
      })
      .addCase(fetchDiscussion.fulfilled, (state, action) => {
        let responseCode = action.payload?.responseCode;
        if (responseCode === 200 && action.payload) {
         state.discussion.data = action.payload.response;
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.discussion.loading = false;
      })
      .addCase(fetchDiscussion.rejected, (state, action) => {
        //state.error = action.error.message;
        state.error = "Something is wrong"
      })
      ;
  },
});
export const {} = discussionSlice.actions;
