import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Navigate,
} from "react-router-dom";
import { lazy, Suspense } from "react";

const Layout = lazy(() => import("../components/common/Layout"));
const MyCourses = lazy(() => import("../pages/MyCourses"));
const MyWebinars = lazy(() => import("../pages/MyWebinars"));
const Learn = lazy(() => import("../pages/Learn"));
const OtpScreen = lazy(() => import("../pages/OtpScreen"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const PlanWebinar = lazy(() => import("../pages/WebinarPlan"));
const Meeting = lazy(() => import("../pages/Meeting"));
const MyCertificate = lazy(() => import("../pages/MyCertificate"));
const ELMSchool = lazy(() => import("../pages/ELMSchool"));
const Profile = lazy(() => import("../pages/Profile"));
const HostCourses = lazy(() => import("../pages/HostCourses"));
const HostWebinars = lazy(() => import("../pages/HostWebinars"))
const Support = lazy(()=> import("../pages/Support"))
const Report = lazy(()=>import("../pages/Report"))
const MyOffers = lazy(()=>import("../pages/MyOffers"))
const MyEvents = lazy(()=> import("../pages/MyEvents"))
const Affiliates = lazy(()=>import("../pages/Affiliates"))
const PromotionPage = lazy(() => import("../pages/PromotionLink"))
const ErrorElement = lazy(()=> import("../components/common/ErrorElement"))
const DiscussionPage = lazy(()=>import("../pages/DiscussionPage"))
const AddCourse = lazy(()=> import("../pages/AddCourse"))
const EditCourse = lazy(()=> import("../pages/EditCourse"))

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        <Route index={true} element={<Navigate replace to={"my-courses"} />} />
        <Route path="my-courses" element={<MyCourses />} />
        <Route
          path="my-webinars"
          element={
            <Suspense fallback={<></>}>
              <MyWebinars />
            </Suspense>
          }
        />
        <Route
          path="webinar-plan"
          element={
            <Suspense fallback={<></>}>
              <PlanWebinar />
            </Suspense>
          }
        />

        <Route
          path="dashboard"
          element={
            <Suspense fallback={<></>}>
              <Dashboard />
            </Suspense>
          }
        />

        <Route
          path="host-courses"
          element={
            <Suspense fallback={<></>}>
              <HostCourses />
            </Suspense>
          }
        />
        <Route
          path="host-webinars"
          element={
            <Suspense fallback={<></>}>
              <HostWebinars/>
            </Suspense>
          }
        />
        <Route
          path="author-link"
          element={
            <Suspense fallback={<></>}>
              <PromotionPage/>
            </Suspense>
          }
        />
        <Route
          path="report"
          element={
            <Suspense fallback={<></>}>
              <Report />
            </Suspense>
          }
        />
        <Route
          path="my-certificates"
          element={
            <Suspense fallback={<></>}>
              <MyCertificate />
            </Suspense>
          }
        />
        <Route
          path="elm-school"
          element={
            <Suspense fallback={<></>}>
              <ELMSchool />
            </Suspense>
          }
        />
        <Route
          path="my-events"
          element={
            <Suspense fallback={<></>}>
              <MyEvents />
            </Suspense>
          }
        />
        <Route
          path="my-offers"
          element={
            <Suspense fallback={<></>}>
              <MyOffers />
            </Suspense>
          }
        />
        <Route
          path="affiliates"
          element={
            <Suspense fallback={<></>}>
              <Affiliates />
            </Suspense>
          }
        />
        <Route
          path="support"
          element={
            <Suspense fallback={<></>}>
              <Support />
            </Suspense>
          }
        />
        <Route
          path="profile"
          element={
            <Suspense fallback={<></>}>
              <Profile />
            </Suspense>
          }
        />
        <Route
          path="author-profile"
          element={
            <Suspense fallback={<></>}>
              <Profile />
            </Suspense>
          }
        />
         <Route
          path="add-course"
          element={
            <Suspense fallback={<></>}>
              <AddCourse />
            </Suspense>
          }
        />
         <Route
          path="discussion"
          element={
            <Suspense fallback={<></>}>
              <DiscussionPage />
            </Suspense>
          }
        />
        <Route
          path="course-detail-edit/:slug/:courseVersionId/:step"
          element={
            <Suspense fallback={<></>}>
              <EditCourse/>
            </Suspense>
          }
        />
      </Route>
      <Route path="/learn">
        <Route
          path=":slug"
          element={
            <Suspense fallback={<></>}>
              <Learn />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="/otp-screen"
        element={
          <Suspense fallback={<></>}>
            <OtpScreen />
          </Suspense>
        }
      />

      <Route path="/meeting">
        <Route
          path=":slug"
          element={
            <Suspense fallback={<></>}>
              <Meeting />
            </Suspense>
          }
        />
      </Route>

      <Route path="*" element={<ErrorElement />} />
    </Route>
  )
);

const CustomRoutes = () => {
  return <RouterProvider router={router} />;
};

export default CustomRoutes;
