import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDetails } from "../utils/fetchRequests";
import { PromotionSliceType } from "../types/PromotionLinkTypes";

const initialState: PromotionSliceType = {
  selfLink: {
    loading: true,
    data: [],
    pagination: {
      isLoading: false,
      isNext: false,
      pageNo: 1,
      total: 0,
    },
  },
  thirdPartyLink: {
    loading: true,
    data: [],
    pagination: {
      isLoading: false,
      isNext: false,
      pageNo: 1,
      total: 0,
    },
  },
  isLoadSave : false,
  isMyCourse : 0,
  error: "",
};

export const fetchselfLink = createAsyncThunk(
  "getSelfLink",
  async (pageNo: number) => {
    try {
      const response = await fetchDetails(
        `promotions/view-auth-link/${pageNo}`,
        2,
        {
          isMyCourse: true,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchThirdPartyLink = createAsyncThunk(
  "getThirdPartyLink",
  async (pageNo: number) => {
    try {
      const response = await fetchDetails(
        `/promotions/view-third-party-auth-link/${pageNo}`,
        2,
        {
          isMyCourse: false,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const saveAuthorLink = createAsyncThunk(
  "saveAuthorLink",
  async ({ url, isMyCourse }: { url: string; isMyCourse: boolean }) => {
    try {
      const response = await fetchDetails(`promotions/generate-auth-url`, 2, {
        url,
        isMyCourse,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const promotionSlice = createSlice({
  name: "promotion",
  initialState,
  reducers: {
    setSelfLInkPageNo: (state, action) => {
      state.selfLink.pagination.pageNo = action.payload;
    },
    setThirdLinkPageNo: (state, action) => {
      state.thirdPartyLink.pagination.pageNo = action.payload;
    },
    setMyCourse : (state, action) => {
        state.isMyCourse = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchselfLink.pending, (state) => {
        state.selfLink.loading =
          state.selfLink.pagination.pageNo === 1 ? true : false;
        state.selfLink.pagination.isLoading =
          state.selfLink.pagination.pageNo > 1 ? true : false;
      })
      .addCase(fetchselfLink.fulfilled, (state, action) => {
        let responseCode = action.payload?.responseCode;
        if (responseCode === 200 && action.payload) {
          let linkData = action.payload.response.resources;
          state.selfLink.pagination.isNext = action.payload.response.isNext;
          state.selfLink.pagination.total = action.payload.response.total;
          state.selfLink.data = [...state.selfLink.data, ...linkData];
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.selfLink.loading = false;
        state.selfLink.pagination.isLoading = false;
      })
      .addCase(fetchselfLink.rejected, (state, action) => {
        state.selfLink.loading = false;
        state.selfLink.pagination.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchThirdPartyLink.pending, (state) => {
        state.thirdPartyLink.loading =
          state.thirdPartyLink.pagination.pageNo === 1 ? true : false;
        state.thirdPartyLink.pagination.isLoading =
          state.thirdPartyLink.pagination.pageNo > 1 ? true : false;
      })
      .addCase(fetchThirdPartyLink.fulfilled, (state, action) => {
        let responseCode = action.payload?.responseCode;
        if (responseCode === 200 && action.payload) {
          let linkData = action.payload.response.resources;
          state.thirdPartyLink.pagination.isNext =
            action.payload.response.isNext;
          state.thirdPartyLink.pagination.total = action.payload.response.total;
          state.thirdPartyLink.data = [
            ...state.thirdPartyLink.data,
            ...linkData,
          ];
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.thirdPartyLink.loading = false;
        state.thirdPartyLink.pagination.isLoading = false;
      })
      .addCase(fetchThirdPartyLink.rejected, (state, action) => {
        state.thirdPartyLink.loading = false;
        state.thirdPartyLink.pagination.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(saveAuthorLink.pending, (state) => {
        state.isLoadSave = true
      })
      .addCase(saveAuthorLink.fulfilled, (state, action) => {
        let responseCode = action.payload?.responseCode;
        if (responseCode === 200 && action.payload) {
          let generatedUrl:string = action.payload.response.generatedLink
         
        let pushData = {id : 1, userId : 1, link : generatedUrl, authorId : 1, isMyCourse: state.isMyCourse, createDate : "0"}
        if(state.isMyCourse === 1) {
            state.selfLink.data.unshift(pushData)
        } else {
            state.thirdPartyLink.data.unshift(pushData)
        }
        
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }
        state.isLoadSave = false
      })
      .addCase(saveAuthorLink.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});
export const { setSelfLInkPageNo, setThirdLinkPageNo, setMyCourse } = promotionSlice.actions;
