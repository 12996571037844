import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDetails } from "../utils/fetchRequests";
import { OrderSliceType } from "../types/UserTypes";

const initialState: OrderSliceType = {
  userOrders: {
    loading: true,
    data: [],
    pagination: {
      isLoading: false,
      total: 0,
      pageNo: 1,
      isNext: false,
    },
  },
  userSettings: {
    loading: true,
    data: [],
  },
};

export const fetchUserOrder = createAsyncThunk(
  "getUserOrder",
  async ({ pageNo }: { pageNo: number }) => {
    try {
      const response = await fetchDetails(
        `users/order-history-in-json/${pageNo}`
      );
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const fetchUserSettings = createAsyncThunk(
  "getUserSettings",
  async () => {
    try {
      const response = await fetchDetails(`users/get-settings`);
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const updateSettings = createAsyncThunk(
  "changeSettings",
  async ({
    fieldName,
    fieldValue,
    currentIndex,
  }: {
    fieldName: string;
    fieldValue: boolean;
    currentIndex: number;
  }) => {
    try {
      const response = await fetchDetails(`users/update-setting`, 2, {
        fieldName,
        fieldValue,
      });
      return { currentIndex, response, fieldValue };
    } catch (err) {
      throw err;
    }
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrderPageNo: (state, action) => {
      state.userOrders.pagination.pageNo = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserOrder.pending, (state) => {
        state.userOrders.loading =
          state.userOrders.pagination.pageNo === 1 ? true : false;
        state.userOrders.pagination.isLoading =
          state.userOrders.pagination.pageNo > 1 ? true : false;
      })
      .addCase(fetchUserOrder.fulfilled, (state, action) => {
        let responseCode = action.payload?.responseCode;
        if (responseCode === 200 && action.payload) {
            state.userOrders.pagination.isNext = action.payload.response.isNext;
           state.userOrders.pagination.total = action.payload.response.total;
          let invoiceData = action.payload.response.invoiceData;
          state.userOrders.data = [...state.userOrders.data, ...invoiceData];
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.userOrders.loading = false;
        state.userOrders.pagination.isLoading = false;
      })
      .addCase(fetchUserOrder.rejected, (state, action) => {
        state.userOrders.loading = false;
        state.userOrders.pagination.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUserSettings.pending, (state) => {
        state.userSettings.loading = true;
      })
      .addCase(fetchUserSettings.fulfilled, (state, action) => {
        let responseCode = action.payload?.responseCode;
        if (responseCode === 200 && action.payload) {
          state.userSettings.data = action.payload.response;
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.userSettings.loading = false;
      })
      .addCase(fetchUserSettings.rejected, (state, action) => {
        state.userSettings.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateSettings.pending, (state) => {})
      .addCase(updateSettings.fulfilled, (state, action) => {
        let responseCode = action.payload.response.responseCode;
        if (responseCode === 200 && action.payload) {
          //state.userSettings.data[currentIndex].value = fieldValue === true ? 1 : 0
          state.userSettings.data[action.payload.currentIndex].value =
            action.payload.fieldValue === true ? 1 : 0;
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }
      })
      .addCase(updateSettings.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});
export const { setOrderPageNo } = orderSlice.actions;
