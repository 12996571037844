import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDetails } from "../utils/fetchRequests";
import { AuthorSliceType } from "../types/CourseTypes";

const initialState: AuthorSliceType = {
  loadingCourses: true,
  courseData: [],
  draftData: [],
  pendingData: [],
  pastWebinar: {
    loading : true,
    data : []
  },
  upcomingWebinar: {
    loading : true,
    data : []
  },
  error: "",
};

export const fetchAuthorCourse = createAsyncThunk(
  "getAuthorCourse",
  async () => {
    try {
      const response = await fetchDetails(`promotions/get-author-courses`);
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const fetchUpcomingWebinar = createAsyncThunk(
  "getUpcomingWebinar",
  async () => {
    try {
      const response = await fetchDetails(`authors/get-upcoming-webinar`);
      return response;
    } catch (err) {
      throw err;
    }
  }
)

export const fetchAuthorPastWebinar = createAsyncThunk(
  "getAuthorPastWebinar",
  async () => {
    try {
      const response = await fetchDetails(`authors/get-past-webinar`);
      return response;
    } catch (err) {
      throw err;
    }
  }
)

export const authorSlice = createSlice({
  name: "school",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuthorCourse.pending, (state) => {
        state.loadingCourses = true;
      })
      .addCase(fetchAuthorCourse.fulfilled, (state, action) => {
        let responseCode = action.payload?.responseCode;
        if (responseCode === 200 && action.payload) {
          state.courseData = action.payload.response.authorCourses;
          state.draftData = action.payload.response.authorDrafts;
          state.pendingData = action.payload.response.authorPendingCourses;
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.loadingCourses = false;
      })
      .addCase(fetchAuthorCourse.rejected, (state, action) => {
        state.loadingCourses = false;
        if (action.error.message) state.error = action.error.message;
      })
      .addCase(fetchUpcomingWebinar.pending, (state) => {
        state.upcomingWebinar.loading = true;
      })
      .addCase(fetchUpcomingWebinar.fulfilled, (state, action) => {
        let responseCode = action.payload?.responseCode;
        if (responseCode === 200 && action.payload) {
          state.upcomingWebinar.data = action.payload.response
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.upcomingWebinar.loading = false;
      })
      .addCase(fetchUpcomingWebinar.rejected, (state, action) => {
        state.upcomingWebinar.loading = false;
        if (action.error.message) state.error = action.error.message;
      })
      .addCase(fetchAuthorPastWebinar.pending, (state) => {
        state.pastWebinar.loading = true;
      })
      .addCase(fetchAuthorPastWebinar.fulfilled, (state, action) => {
        let responseCode = action.payload?.responseCode;
        if (responseCode === 200 && action.payload) {
          state.pastWebinar.data = action.payload.response
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.pastWebinar.loading = false;
      })
      .addCase(fetchAuthorPastWebinar.rejected, (state, action) => {
        state.pastWebinar.loading = false;
        if (action.error.message) state.error = action.error.message;
      });
  },
});
export const {} = authorSlice.actions;
