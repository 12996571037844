import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDetails } from "../utils/fetchRequests";
import { CertificateSliceType } from "../types/CertificateType";
const initialState: CertificateSliceType = {
  elmCertificate: {
    loading: true,
    data: [],
  },
  nseCertificate: {
    loading: true,
    data: [],
  },
  ncdexCertificate: {
    loading: true,
    data: [],
  },
  allInOne: {
    loading: true,
    data: [],
  },
  uploadDocument: {
    loading: false,
    data: null,
  },
  updateStatus: {
    loading: false,
    data: null,
  },
  error: "",
};

export const fetchElmCertficate = createAsyncThunk("getElmCOurse", async () => {
  try {
    const response = await fetchDetails(`certificates/get-elm-courses`);
    return response;
  } catch (err) {
    throw err;
  }
});

export const fetchNSECertificate = createAsyncThunk(
  "getNseCertificate",
  async () => {
    try {
      const response = await fetchDetails(
        `certificates/get-other-certificates`,
        2,
        {
          certificateFor: 1,
        }
      );
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const fetchNCDEXCertificate = createAsyncThunk(
  "getNcdexCertificate",
  async () => {
    try {
      const response = await fetchDetails(
        `certificates/get-other-certificates`,
        2,
        {
          certificateFor: 2,
        }
      );
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const fetchAIOCertificate = createAsyncThunk(
  "getAIOCertificate",
  async () => {
    try {
      const response = await fetchDetails(
        `certificates/get-all-in-one-certificate`
      );
      return response;
    } catch (err) {
      throw err;
    }
  }
);
export const postDocument = createAsyncThunk(
  "addDocument",
  async ({
    invoiceItemId,
    certificateName,
    nseCertificateUploadCaptionId,
    registrationNo,
    marks,
    indexNo,
    isNSE,
  }: {
    invoiceItemId: number;
    certificateName: string;
    nseCertificateUploadCaptionId: number;
    registrationNo: string;
    marks: number;
    indexNo: number;
    isNSE: boolean;
  }) => {
    try {
      const response = await fetchDetails(`certificates/upload-document`, 2, {
        invoiceItemId,
        certificateName,
        nseCertificateUploadCaptionId,
        registrationNo,
        marks,
      });
      return { response, indexNo, isNSE };
    } catch (err) {
      throw err;
    }
  }
);

export const postStatus = createAsyncThunk(
  "addStatus",
  async ({
    invoiceId,
    status,
    currentArrIndex,
    isNSE,
    isAllInOne,
  }: {
    invoiceId: number;
    status: number;
    currentArrIndex: number;
    isNSE?: boolean;
    isAllInOne?:boolean
  }) => {
    try {
      const response = await fetchDetails(`certificates/update-status`, 2, {
        invoiceId,
        status,
      });
      return { response, status, currentArrIndex, isNSE, isAllInOne };
    } catch (err) {
      throw err;
    }
  }
);

export const deleteCertificateDocument = createAsyncThunk(
  "documentDelete",
  async ({ documentId }: { documentId: number }) => {
    try {
      const response = await fetchDetails(`certificates/delete-document`, 2, {
        documentId,
      });
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const certificateSlice = createSlice({
  name: "certificate",
  initialState,
  reducers: {
    setCertificateData: (state, action) => {
      let { certificateIndex, columnIndex, isNSE, responseData } =
        action.payload;
      console.log("respponse", responseData);
      if (isNSE === true) {
        state.nseCertificate.data[
          certificateIndex
        ].userUploadCertificates.splice(columnIndex, 1);
        state.nseCertificate.data[certificateIndex].certificateLogs.push(
          responseData.response.data
        );
      } else {
        state.ncdexCertificate.data[
          certificateIndex
        ].userUploadCertificates.splice(columnIndex, 1);
        state.ncdexCertificate.data[certificateIndex].certificateLogs.push(
          responseData.response.data
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchElmCertficate.pending, (state) => {
        state.elmCertificate.loading = true;
      })
      .addCase(fetchElmCertficate.fulfilled, (state, action) => {
        let payload = action.payload;
        if (payload?.responseCode === 200 && payload) {
          state.elmCertificate.data = payload.response;
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.elmCertificate.loading = false;
      })
      .addCase(fetchElmCertficate.rejected, (state, action) => {
        state.elmCertificate.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchNSECertificate.pending, (state) => {
        state.nseCertificate.loading = true;
      })
      .addCase(fetchNSECertificate.fulfilled, (state, action) => {
        let payload = action.payload;
        if (payload?.responseCode === 200 && payload) {
          state.nseCertificate.data = payload.response;
          let nseData = [];
          // for (let i = 0; i < response.length; i++) {
          //   nseData.push(response[i]);
          //   if (nseData[i].certificateImage !== null) {
          //     // nseData[i].certificateImage = await getCertificateImageWithToken(
          //     //   nseData[i].certificateImage
          //     // );
          //   }
          // }
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.nseCertificate.loading = false;
      })
      .addCase(fetchNSECertificate.rejected, (state, action) => {
        state.nseCertificate.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchNCDEXCertificate.pending, (state) => {
        state.ncdexCertificate.loading = true;
      })
      .addCase(fetchNCDEXCertificate.fulfilled, (state, action) => {
        let payload = action.payload;
        if (payload?.responseCode === 200 && payload) {
          state.ncdexCertificate.data = payload.response;
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.ncdexCertificate.loading = false;
      })
      .addCase(fetchNCDEXCertificate.rejected, (state, action) => {
        state.ncdexCertificate.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAIOCertificate.pending, (state) => {
        state.allInOne.loading = true;
      })
      .addCase(fetchAIOCertificate.fulfilled, (state, action) => {
        let payload = action.payload;
        if (payload?.responseCode === 200 && payload) {
          state.allInOne.data = payload.response;
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.allInOne.loading = false;
      })
      .addCase(fetchAIOCertificate.rejected, (state, action) => {
        state.allInOne.loading = false;
        state.error = action.error.message;
      })
      .addCase(postDocument.pending, (state) => {
        state.uploadDocument.loading = true;
      })
      .addCase(postDocument.fulfilled, (state, action) => {
        let payload = action.payload;
        if (payload.response.responseCode === 200 && payload) {
          state.uploadDocument.data = payload.response;
          if (payload.isNSE) {
            state.nseCertificate.data[
              payload.indexNo
            ].userUploadCertificates.push(
              payload.response.response.certificate
            );
            state.nseCertificate.data[payload.indexNo].certificateLogs.push(
              payload.response.response.log
            );
          } else {
            state.ncdexCertificate.data[
              payload.indexNo
            ].userUploadCertificates.push(
              payload.response.response.certificate
            );
            state.ncdexCertificate.data[payload.indexNo].certificateLogs.push(
              payload.response.response.log
            );
          }
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.uploadDocument.loading = false;
      })
      .addCase(postDocument.rejected, (state, action) => {
        state.uploadDocument.loading = false;
        state.error = action.error.message;
      })
      .addCase(postStatus.pending, (state) => {
        state.updateStatus.loading = true;
      })
      .addCase(postStatus.fulfilled, (state, action) => {
        let payload = action.payload;
        if (payload.response.responseCode === 200 && payload) {
          if (payload.isNSE === true) {
            state.nseCertificate.data[
              payload.currentArrIndex
            ].requestCertificateStatus = payload.status;
            state.nseCertificate.data[
              payload.currentArrIndex
            ].certificateLogs.push(payload.response.response.data);
          } else if(payload.isNSE === false){
            state.ncdexCertificate.data[
              payload.currentArrIndex
            ].requestCertificateStatus = payload.status;
            state.ncdexCertificate.data[
              payload.currentArrIndex
            ].certificateLogs.push(payload.response.response.data);
          }
          state.updateStatus.data = payload.response.response.data;
          if(payload.isAllInOne === true){
            state.allInOne.data[payload.currentArrIndex].requestCertificateStatus = payload.status
          }
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.updateStatus.loading = false;
      })
      .addCase(postStatus.rejected, (state, action) => {
        state.updateStatus.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteCertificateDocument.pending, (state) => {
        state.updateStatus.loading = true;
      })
      .addCase(deleteCertificateDocument.fulfilled, (state, action) => {
        let payload = action.payload;
        if (payload?.responseCode === 200 && payload) {
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.updateStatus.loading = false;
      })
      .addCase(deleteCertificateDocument.rejected, (state, action) => {
        state.updateStatus.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setCertificateData } = certificateSlice.actions;
