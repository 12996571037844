import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDetails } from "../utils/fetchRequests";
import { AffiliateBankDetailsType, AffiliateSliceType } from "../types/AffiliateTypes";

const initialState :AffiliateSliceType = {
  
  registrationData : {
    loading : true,
    data : []
  },
 
  registerUser : {
    loading : false,
    registrationIndexArray : [],
    data : []
  },
  earningData : {
    loading : true,
    data: []
  },
  earningMonthly : {
    loading : false,
    type2Data : [],
    type3Data : [],
    type2ClickedArray : [],
    type3ClickedArray : [],
  },
  linkData : {
    loading : true,
    data : []
  },
  commissions : {
    loading : true,
    commisionData : [],
    categoryData : []
  },
  verifiedData : {
    loading: true,
     details : null,
     affiliateUserDetails : null
  },
  bankDetails : {
    loading : true,
    data: {
      bankHolderName: "",
      name: "",
      bankName: "",
      bankAcNo: "",
      ifscCode: "",
      panCardNo: "",
    },
    isSubmit : false
  },
  error: "",
};


export const fetchregistration = createAsyncThunk(
  "getregistration",
  async () => {
    try {
      const response = await fetchDetails(`affiliates/get-affiliate-registration`);
      return response;
    } catch (err) {
      throw err;
    }
  }
)

export const fetchregisterUser = createAsyncThunk(
  "getregisterUser",
  async ({year, month} : {year : number, month : number}) => {
    try {
      const response = await fetchDetails(`affiliates/get-all-register-user` , 2 , {year, month} );
      return response;
    } catch (err) {
      throw err;
    }
  }
)
export const fetchAffiliatEarning = createAsyncThunk(
  "getAffiliatEarning",
  async () => {
    try {
      const response = await fetchDetails(`affiliates/get-affiliate-earnings`);
      return response;
    } catch (err) {
      throw err;
    }
  }
)

export const fetchEarningMonthly = createAsyncThunk(
  "getEarningMonthly",
  async ({type, year, month} : {type: number, year : number, month : number}) => {
    try {
      const response = await fetchDetails(`affiliates/get-all-earning-monthly` , 2 , { "type":type,
        "year":year,
        "month":month, 
        "isAffiliate":1});
      return {response, type};
    } catch (err) {
      throw err;
    }
  }
)

export const fetchAffiliateLink = createAsyncThunk(
  "getAffiliateLink",
  async () => {
    try {
      const response = await fetchDetails(`affiliates/view-links`);
      return response;
    } catch (err) {
      throw err;
    }
  }
)

export const postUrl = createAsyncThunk(
  "postAffiliateLink",
  async (link : string) => {
    try {
      const response = await fetchDetails(`affiliates/generate-url`, 2, {url : link});
      return response;
    } catch (err) {
      throw err;
    }
  }
)

export const fetchAffiliateCommission = createAsyncThunk(
  "getAffiliateCommision",
  async () => {
    try {
      const response = await fetchDetails(`affiliates/get-affiliate-commission`);
      return response;
    } catch (err) {
      throw err;
    }
  }
)

export const fetchAffiliateVerified =  createAsyncThunk(
  "getAffiliateVerified",
  async ({fromDate, toDate} : {fromDate : string, toDate : string}) => {
    try {
      const response = await fetchDetails(`affiliates/get-affiliate-genuine`, 2 , {fromDate, toDate});
      return response;
    } catch (err) {
      throw err;
    }
  }
)

export const fetchBankDetails =  createAsyncThunk(
  "getAffiliateBankDetails",
  async () => {
    try {
      const response = await fetchDetails(`affiliates/get-afffiliate-bank-details`);
      return response;
    } catch (err) {
      throw err;
    }
  }
)

export const submitBankDetails = createAsyncThunk(
  "addbankData",
  async (bankData : AffiliateBankDetailsType) => {
    try {
      const response = await fetchDetails(`affiliates/save-bank-details` , 2 , bankData);
      return {response, bankData};
    } catch (err) {
      throw err;
    }
  }
)

export const affiliateSlice = createSlice({
  name: "affiliate",
  initialState,
  reducers: {
    manageEarningData : (state, action) =>{
     let {type, clickedIndex} = action.payload
     if(type === 2){
          state.earningMonthly.type2ClickedArray.push(clickedIndex)
     }else  if(type === 3){
          state.earningMonthly.type3ClickedArray.push(clickedIndex)
     }
    },
    manageRegistrationData : (state, action) => {
      state.registerUser.registrationIndexArray.push(action.payload)
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAffiliatEarning.pending, (state) => {
        state.earningData.loading = true
      })
      .addCase(fetchAffiliatEarning.fulfilled, (state, action) =>{
          let responseCode = action.payload.responseCode;
        if (responseCode === 200 ) {
          state.earningData.data = action.payload.response
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.earningData.loading = false;
      })
      .addCase(fetchAffiliatEarning.rejected, (state, action) => {
        if(action.error.message)
        state.error = action.error.message
      state.earningData.loading = false
      })
      .addCase(fetchEarningMonthly.pending, (state) => {
        state.earningMonthly.loading = true
      })
      .addCase(fetchEarningMonthly.fulfilled, (state, action) =>{
          let responseCode = action.payload.response.responseCode;
        if (responseCode === 200 ) {
          if(action.payload.type === 2){
            state.earningMonthly.type2Data.push( action.payload.response.response )
          }else if(action.payload.type === 3){
            state.earningMonthly.type3Data.push(action.payload.response.response)
          }
          
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.earningMonthly.loading = false;
      })
      .addCase(fetchEarningMonthly.rejected, (state, action) => {
        if(action.error.message)
        state.error = action.error.message
      state.earningMonthly.loading = false
      })
      .addCase(fetchAffiliateLink.pending, (state) => {
        state.linkData.loading = true
      })
      .addCase(fetchAffiliateLink.fulfilled, (state, action) =>{
          let responseCode = action.payload.responseCode;
        if (responseCode === 200 ) {
          state.linkData.data = action.payload.response
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.linkData.loading = false;
      })
      .addCase(fetchAffiliateLink.rejected, (state, action) => {
        if(action.error.message)
        state.error = action.error.message
      state.linkData.loading = false
      })
      .addCase(postUrl.pending, (state, action) => {
        state.linkData.loading = true
      })
      .addCase(postUrl.fulfilled, (state, action) =>{
          let responseCode = action.payload.responseCode;
        if (responseCode === 200 ) {
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.linkData.loading = false;
      })
      .addCase(postUrl.rejected, (state, action) => {
        if(action.error.message)
        state.error = action.error.message
      state.linkData.loading = false
      })
      .addCase(fetchAffiliateCommission.pending, (state) => {
        state.commissions.loading = true
      })
      .addCase(fetchAffiliateCommission.fulfilled, (state, action) =>{
          let responseCode = action.payload.responseCode;
        if (responseCode === 200 ) {
          state.commissions.commisionData = action.payload.response.affiliateCommission
          state.commissions.categoryData = action.payload.response.affiliateCategoryList
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.commissions.loading = false;
      })
      .addCase(fetchAffiliateCommission.rejected, (state, action) => {
        if(action.error.message)
        state.error = action.error.message
      state.commissions.loading = false
      })
      .addCase(fetchAffiliateVerified.pending, (state) => {
        state.verifiedData.loading = true
      })
      .addCase(fetchAffiliateVerified.fulfilled, (state, action) =>{
          let responseCode = action.payload.responseCode;
        if (responseCode === 200 ) {
          state.verifiedData.details = action.payload.response.details
            state.verifiedData.affiliateUserDetails = action.payload.response.affiliateUserDetails       
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.verifiedData.loading = false;
      })
      .addCase(fetchAffiliateVerified.rejected, (state, action) => {
        if(action.error.message)
        state.error = action.error.message
      state.verifiedData.loading = false
      })
      .addCase(fetchregisterUser.pending, (state) => {
        state.registerUser.loading = true
      })
      .addCase(fetchregisterUser.fulfilled, (state, action) =>{
          let responseCode = action.payload.responseCode;
        if (responseCode === 200 ) {
          state.registerUser.data.push(action.payload.response.data)      
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.registerUser.loading = false;
      })
      .addCase(fetchregisterUser.rejected, (state, action) => {
        if(action.error.message)
        state.error = action.error.message
      state.registerUser.loading = false
      })
      .addCase(fetchregistration.pending, (state) => {
        state.registrationData.loading = true
      })
      .addCase(fetchregistration.fulfilled, (state, action) =>{
          let responseCode = action.payload.responseCode;
        if (responseCode === 200 ) {
          state.registrationData.data  = action.payload.response
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.registrationData.loading = false;
      })
      .addCase(fetchregistration.rejected, (state, action) => {
        if(action.error.message)
        state.error = action.error.message
      state.registrationData.loading = false
      })
      .addCase(fetchBankDetails.pending, (state) => {
        state.bankDetails.loading = true
      })
      .addCase(fetchBankDetails.fulfilled, (state, action) =>{
        let responseCode = action.payload.responseCode;
        if (responseCode === 200 ) {
          state.bankDetails.data  = action.payload.response      
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }
        state.bankDetails.loading = false;
      })
      .addCase(fetchBankDetails.rejected, (state, action) => {
        if(action.error.message)
        state.error = action.error.message
        state.bankDetails.loading = false
      })
      .addCase(submitBankDetails.pending, (state) => {
        state.bankDetails.isSubmit = true
      })
      .addCase(submitBankDetails.fulfilled, (state, action) =>{
        let responseCode = action.payload.response.responseCode;
        if (responseCode === 200 ) {
       
            
            state.bankDetails.data = action.payload.bankData
               
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }
        state.bankDetails.isSubmit = false;
      })
      .addCase(submitBankDetails.rejected, (state, action) => {
        if(action.error.message)
        state.error = action.error.message
        state.bankDetails.isSubmit = false
      })
      ;
  },
});
export const {manageEarningData, manageRegistrationData} = affiliateSlice.actions;
