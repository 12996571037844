
import { setError } from "../services/errorSlice";
import { store } from "../store/store";

export const fetchDetails = async (
  url: string,
  method_type: number = 1,
  data:Object | undefined= {},
  isFormData: boolean = false
) => {
  let apiUrl = process.env.REACT_APP_LMS_API_URL  ? process.env.REACT_APP_LMS_API_URL + url : "";
  let response, responseCode;
  let options: RequestInit = {
    method: "POST",
    credentials: "include",
  };
  let bodyData: BodyInit | null | undefined;
  try {
    if (method_type === 1) {
      options.method = "GET";
    } else if (method_type === 2 && data) {
      bodyData = isFormData ? data as FormData : JSON.stringify(data);
      options.headers =  { "Content-Type": "application/json" }
      options.body = bodyData;
    }
    response = await fetch(apiUrl, options)
    .then((r) => {
      responseCode = r.status;

      if (r.status === 401) {
        //throw new Error("401");
       store.dispatch(setError({errorCode : r.status}))
      } else if (r.status === 403) {
        //throw new Error("403");
        return r.json();
      } else if (r.status === 422) {
        //return r.json();
        //throw new Error("422");
        return r.json();
      } else if (r.status === 404) {
        //throw new Error("404");
        return r.json();
      } else if (r.status === 200) {
        return r.json();
      }
    });
  } catch (error) {
    throw error
  } finally {
    return {
      response: response,
      responseCode: responseCode,
    };
  }
};

/*

  const fetchData = async (url, method_type = 1, data = null, token = null, headerType = 'json') => {
    store.dispatch({
        type: accountTypes.REFRESH_LOGIN_TOKEN_RESET
    })

    const storeState = store.getState()

    let response = null, responseCode = null

    let headers = {
        'Content-Type': 'application/json'
    }

    if (token) {
        headers = {
            'Authorization': 'Bearer ' + storeState.accountState.token
        }
    }
    if (headerType === 'json' && token) {
        headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + storeState.accountState.token
        }
    }


    let options

    if (method_type === 2) {
        options = {
            method: "GET",
            headers,
            credentials: "include"
        }
    } else if (method_type === 1){
        options = {
            method: "POST",
            headers,
            body: data,
            credentials: "include"
        }
    } else if(method_type===3){
        options = {
            method: "DELETE",
            headers,
            body: data,
            credentials: "include"
        }
    }

    try {
        response = await fetch(process.env.REACT_APP_API_LINK + url, options).then((r) => {
            responseCode = r.status

            if (r.status === 401) {
                store.dispatch({
                    type: accountTypes.REFRESH_LOGIN_TOKEN
                })
                throw new Error("401")
            }
            else if (r.status === 403) {
                store.dispatch({
                    type: accountTypes.ACCESS_DENIED
                })
                throw new Error("403")
            }
            else if (r.status === 422) {
                return r.json()
                // throw new Error("422")
            }
            else if (r.status === 200) {

                return r.json()
            }
        })

        if (responseCode === 422) {
            store.dispatch({
                type: accountTypes.VALIDATION_ERROR,
                payload: response.data
            })
        }
        if (responseCode === 200 && (method_type === 1 || method_type === 3)) {
            //for post only
            store.dispatch({
                type: toasterTypes.SHOW_TOAST,
                payload: {
                    toast_type: 'success',
                    toast_msg: response.msg
                }
            })
        }
    } catch (error) {
        response = null
    } finally {
        return {
            response: response,
            responseCode: responseCode
        }
    }
}
    */
