import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDetails } from "../utils/fetchRequests";
import { CourseCommentSliceType } from "../types/CommentType";

const initialState: CourseCommentSliceType = {
  comments: {
    data: [],
    loading: true,
    pagination: {
      isLoading: false,
      isNext: false,
      pageNo: 1,
      total: 0,
    },
  },
  isParmitComment: false,
  isParmitCommentReply: false,
  processSaving: false,
  successMsg: "",
  error: "",
};

export const fetchComments = createAsyncThunk(
  "getComments",
  async ({
    pageNo,
    courseId,
    authorPanelNumber,
  }: {
    pageNo: number;
    courseId: number;
    authorPanelNumber: number;
  }) => {
    try {
      const response = await fetchDetails(
        `discussions/get-comments/${pageNo}`,
        2,
        {
          courseId,
          authorPanelNumber,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const saveVote = createAsyncThunk(
  "saveVote",
  async ({
    courseCommentId,
    voteType,
    parentIndex,
    childIndex,
  }: {
    courseCommentId: number;
    voteType: number;
    parentIndex: number;
    childIndex: number;
  }) => {
    try {
      const response = await fetchDetails(`discussions/comment-vote`, 2, {
        courseCommentId,
        voteType,
      });
      return { response, parentIndex, childIndex, voteType };
    } catch (error) {
      throw error;
    }
  }
);

export const saveComments = createAsyncThunk(
  "saveComments",
  async ({
    courseId,
    courseUnitId,
    description,
    title,
    parentId
  }: {
    courseId: number;
    courseUnitId : number;
    description: string;
    title?: string;
    parentId?: number;
  }) => {
    try {
      let postData
      if(parentId !== undefined) {
         postData = {courseId, courseUnitId, parentId, description}
      }
      else {
         postData = {courseId, courseUnitId, title, description}
      }
      const response = await fetchDetails(`discussions/save-comment-post`, 2, postData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const commentSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    setPageNo: (state, action) => {
      state.comments.pagination.pageNo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchComments.pending, (state) => {
        state.comments.loading =
          state.comments.pagination.pageNo === 1 ? true : false;
        state.comments.pagination.isLoading =
          state.comments.pagination.pageNo > 1 ? true : false;
      })
      .addCase(fetchComments.fulfilled, (state, action) => {
        let responseCode = action.payload.responseCode;
        if (responseCode === 200 && action.payload) {
          let commentsData = action.payload.response.data;
          state.comments.pagination.isNext = action.payload.response.isNext;
          state.comments.pagination.total = action.payload.response.total;
          state.error = "";
          if (state.comments.pagination.pageNo === 1) {
            state.comments.data = action.payload.response.data;
          } else {
            state.comments.data = [...state.comments.data, ...commentsData];
          }
        } else {
          state.error = "Something is wrong";
        }
        state.comments.pagination.isLoading = false;
        state.comments.loading = false;
      })
      .addCase(fetchComments.rejected, (state, action) => {
        //state.error = action.error.message;
        state.error = action.error.message;
      })
      .addCase(saveVote.pending, (state) => {
        state.processSaving = true;
      })
      .addCase(saveVote.fulfilled, (state, action) => {
        let responseCode = action.payload.response.responseCode;
        if (responseCode === 200 && action.payload) {
          state.successMsg = action.payload.response.response.message;
        
          if (action.payload.response.response.statusCode === 1) {
            if(action.payload.voteType === 1){
              if (action.payload.childIndex === -1)
                state.comments.data[action.payload.parentIndex].totalUpVote += 1;
              else
                state.comments.data[
                  action.payload.parentIndex
                ].childcourseComments[action.payload.childIndex].totalUpVote += 1;
            } else if(action.payload.voteType === 2){
                  if (action.payload.childIndex === -1)
              state.comments.data[
                action.payload.parentIndex
              ].totalDownVote += 1;
            else
              state.comments.data[
                action.payload.parentIndex
              ].childcourseComments[
                action.payload.childIndex
              ].totalDownVote += 1;
            }
           
          } 
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }
      })
      .addCase(saveVote.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(saveComments.pending, (state, action) => {
        state.processSaving = true
      })
      .addCase(saveComments.fulfilled, (state, action) => {
        let responseCode = action.payload.responseCode;
        if (responseCode === 200 && action.payload) {
           state.error = ""
        } else{
            state.error = "Something is wrong"
        }
        state.processSaving  = false
      })
      .addCase(saveComments.rejected, (state, action) => {
        state.error = "something is wrong"
      })
      ;
  },
});
export const { setPageNo } = commentSlice.actions;
