import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDetails } from "../utils/fetchRequests";
import { OfferSliceType } from "../types/OfferTypes";

const initialState: OfferSliceType = {
  couponData: {
    loading: true,
    data: [],
  },
  externalOffer: {
    loading: true,
    data: [],
    kotaOffer: {
      isAvailed: false,
      data: null,
    },
  },
  error: "",
};

export const fetchCouopon = createAsyncThunk("getCoupon", async () => {
  try {
    const response = await fetchDetails(`my-offers/get-coupon-code`);
    return response;
  } catch (err) {
    throw err;
  }
});

export const fetchExternalOffer = createAsyncThunk(
  "getExternalOffer",
  async () => {
    try {
      const response = await fetchDetails(
        `my-offers/get-external-offer-by-user`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const saveExtaOffer = createAsyncThunk(
  "saveExtraOffer",
  async ({ isOptIn, offerId }: { isOptIn: number; offerId: number }) => {
    try {
      const response = await fetchDetails(
        `my-offers/save-user-extra-offer`,
        2,
        { isOptIn, offerId }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const offerSlice = createSlice({
  name: "offer",
  initialState,
  reducers: {
    changeCurrentIndex: (state, action) => {
      if (state.externalOffer.data !== null)
        state.externalOffer.data[action.payload].isOptIn = 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCouopon.pending, (state) => {
        state.couponData.loading = true;
      })
      .addCase(fetchCouopon.fulfilled, (state, action) => {
        let responseCode = action.payload?.responseCode;
        if (responseCode === 200 && action.payload) {
          state.couponData.data = action.payload.response;
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.couponData.loading = false;
      })
      .addCase(fetchCouopon.rejected, (state, action) => {
        state.couponData.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchExternalOffer.pending, (state) => {
        state.externalOffer.loading = true;
      })
      .addCase(fetchExternalOffer.fulfilled, (state, action) => {
        let responseCode = action.payload?.responseCode;
        if (responseCode === 200 && action.payload) {
          state.externalOffer.data = action.payload.response.externalOffer;
          state.externalOffer.kotaOffer.isAvailed =
            action.payload.response.data.isAvailed;
          state.externalOffer.kotaOffer.data =
            action.payload.response.data.details.offer ||
            action.payload.response.data.details.offer.length > 0
              ? action.payload.response.data.details.offer
              : null;
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.externalOffer.loading = false;
      })
      .addCase(fetchExternalOffer.rejected, (state, action) => {
        state.externalOffer.loading = false;
        state.error = action.error.message;
      })
      .addCase(saveExtaOffer.pending, (state) => {})
      .addCase(saveExtaOffer.fulfilled, (state, action) => {
        let responseCode = action.payload?.responseCode;
        if (responseCode === 200 && action.payload) {
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }
      })
      .addCase(saveExtaOffer.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});
export const { changeCurrentIndex } = offerSlice.actions;
