import { configureStore } from '@reduxjs/toolkit'
import { webinarSlice } from '../services/webinarSlice'
//import { productSlice } from '../services/productSlice'
import { courseSlice } from '../services/courseSlice'
import { errorSlice } from '../services/errorSlice'
import { userSlice } from '../services/userSlice'
import { reviewSlice } from '../services/reviewSlice'
import { certificateSlice } from '../services/certtificateSlice'
import { schoolSlice } from '../services/schoolSlice'
import { offerSlice } from '../services/offerSlice'
import { orderSlice } from '../services/orderSlice'
import { promotionSlice } from '../services/promotionSlice'
import { discussionSlice } from '../services/discussionSlice'
import { commentSlice } from '../services/commentSlice'
import { authorSlice } from '../services/authorSlice'
import { affiliateSlice } from '../services/affiliateSlice'
import { hostCourseSlice } from '../services/hostCourseSlice'



export const store = configureStore({
  reducer:{
    courseSlice : courseSlice.reducer,
    webinarSlice: webinarSlice.reducer,
    errorSlice : errorSlice.reducer,
    userSlice : userSlice.reducer,
    reviewSlice : reviewSlice.reducer,
    certificateSlice : certificateSlice.reducer,
    schoolSlice : schoolSlice.reducer,
    offerSlice : offerSlice.reducer,
    orderSlice : orderSlice.reducer,
    promotionSlice : promotionSlice.reducer,
    discussionSlice : discussionSlice.reducer,
    commentSlice : commentSlice.reducer,
    authorSlice : authorSlice.reducer,
    affiliateSlice : affiliateSlice.reducer,
    hostCourseSlice : hostCourseSlice.reducer
   // [productSlice.reducerPath] : productSlice.reducer,
  },
  // middleware:(getDefaultMiddleware)=>getDefaultMiddleware().concat(productSlice.middleware)
})


export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
